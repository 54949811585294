<template>
  <EmTooltip>
    <template #activator="{attrs, props}">
      <EmIcon icon="mdi-cellphone-wireless" v-bind="{...attrs, ...$attrs, ...props}" />
    </template>
    <template #default>
      <span>
        This is a short phone number
      </span>
    </template>
  </EmTooltip>
</template>
